<template>
  <FeedbackState class="confirmation">
    <template #heading>
      <h2 class="step__main-title">
        {{ $t('The return is confirmed!') }}
      </h2>
    </template>
    <template
      v-if="!isLabelless"
      #image
    >
      <div>
        <img
          data-test="success-image"
          class="feedback__image"
          src="@/assets/img/congrats.svg"
          alt=""
        >
      </div>
    </template>
    <template
      v-if="hasLabel"
      #text
    >
      <p>
        {{ $t('Click below and download your return label.') }}
      </p>
      <p v-if="hasLabel">
        <strong>{{ $t('Can’t print the label right now?') }}</strong><br>
        {{ $t('Don’t worry, you will receive it via email shortly.') }}
      </p>
    </template>
    <template
      v-else-if="isPickUp"
      #text
    >
      <p>{{ $t('You will also receive it via email shortly.') }}</p>
      <p>{{ $t('The carrier will contact you by email to arrange a pick-up.') }}</p>
    </template>
    <template
      v-else-if="isLabelless"
      #text
    >
      <p>
        <img
          v-if="qrCodeURL"
          class="qr-code"
          data-test="qr-code"
          :src="qrCodeURL"
          alt="QR Code image"
        >
      </p>
      <br>
      <p>
        <b>{{ $t('Show this QR code at the drop-off point.') }}</b>
      </p>
      <p>{{ $t('You will also receive it via email shortly.') }}</p>
    </template>
    <template
      v-else-if="storeAddress"
      #text
    >
      <p>{{ $t('Bring the items you want to return in the original condition to the selected store, please.') }}</p>

      <div class="card">
        <p class="card__title">
          {{ $t('Return in store') }}
        </p>
        <AddressFormatted v-bind="storeAddress" />
      </div>
    </template>

    <template
      v-if="hasLabel"
      #footer
    >
      <UiButton
        mode="primary"
        class="main-button"
      >
        <a
          v-if="downloadLink"
          class="main-button__btn"
          data-test="download-link"
          :style="brandStyle"
          :href="downloadLink"
        >
          {{ $t('Download label') }}
        </a>
        <span
          v-else
          class="main-button__mock main-button__btn main-button__btn--disabled"
          data-test="download-mock"
        >
          {{ $t('Download label') }}
        </span>
      </UiButton>
    </template>

    <template
      v-else-if="isLabelless"
      #footer
    >
      <UiButton
        mode="primary"
        class="main-button"
      >
        <a
          v-if="downloadLink"
          class="main-button__btn"
          data-test="download-link"
          :style="brandStyle"
          :href="downloadLink"
        >
          {{ $t('Save QR code') }}
        </a>
      </UiButton>
    </template>

    <template
      v-else-if="shopURL"
      #footer
    >
      <UiButton
        mode="primary"
        class="main-button"
      >
        <a
          v-if="shopURL"
          :href="shopURL"
          class="main-button__btn"
          :style="brandStyle"
        >{{ $t('Continue shopping') }}</a>
      </UiButton>
    </template>
  </FeedbackState>
</template>

<script>
import { brandColourMixin } from '@/components/mixins'
import { mapState } from 'vuex'
import { UiButton } from '@sendcloud/ui-library'

import { Mixpanel } from '@/tracking'
import FeedbackState from '@/components/common/FeedbackState.vue'
import AddressFormatted from '@/components/common/AddressFormatted.vue'

export default {
  name: 'ConfirmationView',
  components: {
    UiButton,
    FeedbackState,
    AddressFormatted,
  },
  mixins: [brandColourMixin],
  beforeRouteEnter(_to, from, next) {
    if (from.name === null) {
      next()
    } else {
      next((vm) => {
        Mixpanel.track('return announced', {
          refundMethodCode: vm.selectedRefund ? vm.selectedRefund.code : 'none',
          returnLocationType: vm.returnLocationType,
          returnItemsCount: vm.returnedItems.length || 1,
          returnReasonsIds: vm.returnReasonsIds,
        })
      })
    }
  },
  computed: {
    ...mapState({
      hasLabel: (state) => {
        return ['dropoff', 'post-office'].includes(state.returnLocationType)
      },
      isPickUp: (state) => {
        return ['pickup'].includes(state.returnLocationType)
      },
      isLabelless: (state) => {
        return ['drop_off_labelless'].includes(state.returnLocationType)
      },
      downloadLink: (state) => {
        const url = state.downloadLabelURL
        if (url === undefined) {
          return ''
        }
        const userLanguage = encodeURIComponent(state.userLanguage)
        const lang = url.includes('?') ? `&language=${userLanguage}` : `?language=${userLanguage}`
        return `${url}${lang}`
      },
      qrCodeURL: (state) => state.qrCodeURL,
      storeAddress: (state) => {
        return state.storeAddresses.find((address) => address.id === state.selectedStore)
      },
      shopURL: (state) => state.brand && state.brand.website,
      selectedRefund: (state) => state.selectedRefund,
      detachedReason: (state) => state.detachedReason,
      returnReasons: (state) => state.returnReasons,
      returnLocationType: (state) => state.returnLocationType,
      returnedItems: (state) => state.returnedItems,
    }),
    returnReasonsIds() {
      if (this.returnedItems.length) {
        return this.returnedItems.map((item) => item.reason)
      }

      if (this.detachedReason) {
        return [this.detachedReason.reason]
      }

      return [-1]
    },
  },
}
</script>
<style lang="scss" scoped>
.feedback__heading {
  .step__main-title {
    margin: 0;
  }
}
.card {
  text-align: left;
  width: 100%;
  margin-top: 2rem;
}

.card__title {
  font-weight: 700;
  font-size: 1.2rem;
  margin-top: 0;
}
.qr-code {
  width: 100%;
  height: 100%;
  max-width: 200px;
  min-height: 200px;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.15);
  display: inline-block;
  text-align: center;
}
</style>
