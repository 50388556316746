<template>
  <TheWrapper class="feedback">
    <div v-if="$slots.image">
      <slot name="image" />
    </div>
    <div class="feedback__heading">
      <slot name="heading" />
    </div>
    <div class="feedback__body">
      <slot name="text" />
    </div>
    <slot name="footer" />
  </TheWrapper>
</template>
<script>
import TheWrapper from '@/components/layout/TheWrapper'
export default {
  name: 'FeedbackState',
  components: {
    TheWrapper,
  },
}
</script>
<style lang="scss" scoped>
.feedback {
  text-align: center;
}
.feedback__heading {
  font-size: 1.125rem;
  font-weight: 700;
  margin: 1.25rem auto 1rem;
}
.feedback__body {
  margin: 0 auto 2.125rem;
  width: 100%;
}
</style>

<style lang="scss">
.feedback__image {
  max-width: 187px;
  max-height: 187px;
  display: block;
  margin: 40px auto 20px auto;
}
</style>
